import React from 'react';
import { Box, Container } from 'theme-ui';
import Accordion from 'components/accordion/accordion';
import SectionHeader from 'components/section-header';


const accordionData = [
  {
    isExpanded: false,
    title: 'The Method and Apparatus for Storing Transaction History and Proof of Ownership Utilizing Distributed Computing',
    contents: (
      <div>
        The invention describes a method for determining the authenticity of digital securities using distributed computing. 
        The method includes generating a first asset block through pre-set consensus nodes in a private blockchain based on first asset information, 
        creating at least one token corresponding to the digital securities based on the first asset block, determining the authenticity of at least one token, 
        and registering the digital securities based on the authenticity of at least one token.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'The Method and Apparatus for Determining the Authenticity of Digital Security Utilizing Distributed Computing',
    contents: (
      <div>
        The invention outlines a method for performing transactions of digital securities using distributed computing. 
        The process involves generating a first asset block through a private blockchain based on first asset information, 
        issuing at least one token based on the first asset block, 
        a first terminal holding a first quantity of the token selling this quantity to a second terminal to complete a transaction, 
        and updating the first asset block through the private blockchain based on the completed transaction.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'The Method and Apparatus for Encryption of Blockchain-based Transaction History Proof and Ownership Transfer',
    contents: (
      <div>
        The invention details a method for encrypting blockchain-based transaction history proof and ownership transfer using distributed computing. 
        
        It involves generating a first asset block through pre-set consensus nodes in a private blockchain based on first asset information, 
        creating at least one token corresponding to the digital securities based on the first asset block, 
        distributing the tokens to at least one initial investor, and registering the tokens distributed to initial investors on an exchange node.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'The Method and Apparatus for Distributing Expected Profits Based on Blockchain-based Proof of Ownership',
    contents: (
      <div>
        The invention describes a method for distributing expected profits based on blockchain-based proof of ownership using distributed computing. 

        The method includes generating a first asset block through pre-set consensus nodes in a private blockchain based on first asset information, 
        creating at least one token corresponding to the digital securities based on the first asset block, distributing these tokens to multiple terminals, 
        generating dividend information based on the first asset information, and distributing dividends to multiple terminals based on the dividend information.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'The Method and Apparatus for Performing Trading of Digital Security Utilizing Distributed Computing',
    contents: (
      <div>
        The invention presents a method for performing trading of digital securities using distributed computing. 

        The method includes generating a first asset block through a private blockchain based on first asset information, 
        issuing at least one token based on the first asset block, 
        completing a transaction by a first terminal holding a certain quantity of the token selling this quantity to a second terminal, 
        and updating the first asset block based on the completed transaction through the private blockchain.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'A Blockchain Bridge System for Sharing Data Between Independent Blockchain Networks',
    contents: (
      <div>
        The invention relates to the blockchain bridge system for data sharing between independent blockchain networks. 

        The system includes a first connection peer registered by the first blockchain network, a second connection peer registered by the second blockchain network, and a bridge node. 

        The bridge node detects an update event of new transaction data through the first or the second connection peer between the two blockchain networks and adds the new transaction data to the different network.

        Using this system, content updated in one blockchain network can be added to a different blockchain network, allowing data to be shared between independent networks.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'A Personal Information Management System Based on Blockchains and the Method Thereof',
    contents: (
      <div>
        The invention relates to a blockchain-based personal information management system and method. 

        It involves a blockchain network consisting of multiple peers, each maintaining a blockchain ledger where transaction messages are recorded as blocks. The blockchain network includes a first peer that synchronizes the blockchain ledgers of all peers and produces a transaction message (information request message) requesting personal information. 

        A request server, represented by a second peer in the network, delivers this request to the first peer. If the user terminal agrees to provide personal information, the request server creates an agreement on offering message. 

        This agreement is delivered to a third peer (the certificate server), which then sends an information offer message containing the personal information to the providing server, which completes the process by delivering the information offer message to the third peer.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'A Decision-making System Using Blockchains by Verifying Conditions',
    contents: (
      <div>
        The invention relates to a blockchain-based decision-making system that verifies conditions for decision-making. 

        The system includes a relaying server that records acknowledgment messages within a blockchain network, using an encryption key of an asymmetric key pair to encode the resolution key. It also involves a key management server and a resolution server that handle the resolution request messages within the blockchain network. 

        Upon receiving an acknowledgment key from a user terminal, if the resolution request message is confirmed, the resolution request content is delivered to the user terminal within the blockchain network. The key management server extracts combinations of users who satisfy the resolution conditions and assembles the acknowledgment key for each user.

        The resolution server collects acknowledgment messages from the blockchain network, decrypts the acknowledgment keys, and obtains the resolution key. 

        By using this system, the decryption key for the resolution key is prepared according to the user key matching the resolution condition. If the resolution condition is met, the resolution key is automatically obtained, enhancing security while automating the resolution process.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Method, Apparatus, and Computer-readable Medium for Voting Management and Aggregation That Guarantees Transparency Using Blockchain',
    contents: (
      <div>
        The invention pertains to electronic voting and official vote counting using an online system. Voting information is stored in terminals physically distributed in a blockchain mode to prevent searching for individual votes cast in the election. 

        Even when voting information is read from dispersed terminals, it is impossible to trace which voter cast which vote, ensuring complete secrecy in the voting process. Voters cast votes using their voter accounts, which are issued a unique identifier for each vote.

        The voting information includes the vote data, the first account information (the voter's identifier), and the number of votes for each candidate account. The votes are securely stored in multiple distributed terminals comprising the blockchain, preventing the comparison of vote counts among candidates.

        The voting information is later collected from these terminals for official vote counting. The system ensures that the vote data, including encrypted voter account information and number of votes for each candidate, remains confidential and tamper-proof throughout the voting and counting process.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'A System for Role-based Data Protection Using the Off-chain Ledger on the Blockchain Network',
    contents: (
      <div>
        The invention relates to a role-based data protection system using an off-chain ledger on a blockchain network. 

        The blockchain network consists of multiple peers, each maintaining a blockchain ledger synchronized across all peers. The system enables user access control based on their roles, with role-specific operations conducted on data stored in the off-chain ledger of the blockchain network. 

        The system defines roles and assigns users to these roles, allowing them to access private data according to their assigned roles. The blockchain application calls the chain code according to the role policy, which processes the data operations on the off-chain ledger.

        Using this system, user roles are designated to access the off-chain ledger, and private data is encrypted according to these roles, ensuring the confidentiality and integrity of off-chain data on the blockchain network.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'A Blockchain Network System Using Division Distributed Operation of Peers’ Cluster and the Distributed Operation Method Thereof',
    contents: (
      <div>
        The invention relates to a distributed arithmetic-based blockchain network system utilizing a cluster of peers and a distributed arithmetic method. 

        The system includes multiple connected peers, each containing multiple computing nodes. When a new block (first block) is produced from transaction data by a new peer (first peer), the transaction data is divided into multiple partitioned data, which is then distributed across its computing nodes. 

        The hash values of these partitioned data are computed in each node and merged to set the block hash value in the block header of the first block. Other peers, upon receiving the first block, perform a similar process by dividing the transaction data into partitioned data, distributing it to their computing nodes, and merging the computed hash values.

        The computed block hash value is compared with the recorded block hash value in the first block to verify its integrity. 

        By using this system and method, transaction data are divided, and the hash value operation speed is improved across multiple computing nodes, enhancing the efficiency of distributed operations in the blockchain network.
      </div>
    ),
  },
];

const Patents = () => {
  return (
    <Box as="section" id="patents" sx={styles.section}>
      <Container sx={styles.accordion.container}>
      <Box sx={styles.accordion}>
        <SectionHeader
          title="Patents"
          slogan="Patents about Blockchain, DLT and STO"
        />
        <Accordion items={accordionData} />
        </Box>
      </Container>
    </Box>
  );
};
export default Patents;

const styles = {
  section: {
    overflow: 'hidden',
    pt: ['115px', null, null, '140px', '150px', '170px', '185px'],
  },
  accordion: {
    container: {
      maxWidth: '900px',
    },
  },
};

import React from 'react';
import { StickyProvider } from 'contexts/app/app.provider';
import Seo from 'components/seo';
import LayoutAbout from 'components/layout-about';
import Certificates from 'sections/certificates';
import Patents from 'sections/patents';
import Projects from 'sections/projects';



import 'rc-drawer/assets/index.css';
import 'react-modal-video/css/modal-video.min.css';


export default function IndexPage() {
  return (
    <StickyProvider>
      <LayoutAbout>
        <Seo
          title="FUNBLE Inc."
          description="Blockchain-based FinTech Company in South Korea."
        />
        <Certificates />
        <Patents />
        <Projects />
      </LayoutAbout>
    </StickyProvider>
  );
}

import React from 'react';
import { Box, Container } from 'theme-ui';
import Accordion from 'components/accordion/accordion';
import SectionHeader from 'components/section-header';


const accordionData = [
  {
    isExpanded: false,
    title: 'Blockchain based Original Copy of Power of Attorney (Notarized) System Development',
    contents: (
      <div>
        This project is a pilot initiative aimed at establishing a blockchain-based system for issuing Apostille and consular legalizations. 
        Conducted from May to December 2018, the project developed a system using Hyperledger Fabric. 
        The system is designed to provide secure and efficient document authentication, offering functionalities such as issuance, inquiry, and cancellation through APIs. 
        The project assessed the applicability of blockchain technology both domestically and internationally, emphasizing the innovation of the associated technologies. 
        Through this, it aimed to explore new market opportunities and enhance public services, such as national safety.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Blockchain based Carbon Credit Trading System',
    contents: (
      <div>
        This project is a pilot initiative focused on enhancing the offset registry system using blockchain technology. 
        The offset registry system manages and certifies greenhouse gas emission reductions, aiming to improve data transparency and reliability through blockchain. 
        The project has developed a blockchain-based emission trading platform, integrating functions such as approval of external projects, certification of performance, 
        and conversion of emission credits. This enables tamper-proof data management and real-time information sharing, 
        with plans for continuous system expansion and functionality enhancement. Ultimately, the system aims to reduce social costs through greenhouse gas reduction 
        and improve public services.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Blockchain based REC(Renewable Energy Certificates) System Development',
    contents: (
      <div>
        The project aims to establish a blockchain-based REC (Renewable Energy Certificate) integrated management system. 
        This initiative focuses on enhancing data integration and building a unified database among obligated suppliers, 
        utilizing a common blockchain platform to increase data transparency and efficiency. By leveraging Blockchain as a Service (BaaS), 
        the project provides integrated services to public power corporations and renewable energy producers. 
        Additionally, the project incorporates a Decentralized Identity (DID) system to streamline the authentication process for renewable energy producers 
        and prevent duplicate registrations. This system standardizes data sharing and authentication procedures among institutions, 
        improving workflow efficiency and enhancing administrative credibility.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Blockchain based Metro Bus Accounting Ledger(ERP) Development',
    contents: (
      <div>
        The project aims to establish an accounting sharing system for the semi-public operation of city buses in Busan. 
        This system enhances the management and transparency of financial support for bus operations while improving efficiency 
        by sharing management information among bus companies. Blockchain technology is implemented to securely record the details 
        of financial support transactions and prevent any tampering with various accounting data. 
        The project also supports the standardization of accounting data, allowing for more efficient management and financial control of bus companies. 
        Enhanced system security measures protect sensitive management information, and the introduction of predictive and analytical tools aids 
        in the long-term financial planning of the bus operations.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Blockchain Platform Development for Electricity Trade Recording Ledger',
    contents: (
      <div>
        This project aims to develop blockchain-based application services to enhance the reliability and distribution of electricity data. 
        Utilizing KEPCO's blockchain platform, the project will establish a power data labeling system and an external service access management system. 
        Key developments include power data labeling, API and SDK development, and building a data distribution and verification system. 
        Blockchain technology is employed to transparently manage the generation, distribution, and verification processes of electricity data, promoting the RE100 initiative 
        and energy data transition. Additionally, the project implements a high-availability and secure system for data exchange with external agencies and verifies 
        the functionality of the developed services through various simulation programs.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Energy Supply Chain Management Platform for Tracking Renewable Energy Supply and Consumption',
    contents: (
      <div>
        This project aims to develop and build the KEPCO blockchain platform and its services. 
        The main objectives are to enhance the reliability of electricity data and streamline data management and distribution. 
        The project leverages KEPCO's specialized blockchain architecture design to implement a high-performance, high-security, 
        and future-proof platform. The platform ensures data integrity through distributed consensus algorithms and supports secure document issuance 
        and verification via DID/authentication services. The project maximizes management efficiency with automatic deployment of smart contracts 
        and UI-based management capabilities. It also includes blockchain consulting to discover use cases and develop expansion models. Furthermore, 
        on-site intensive training and technology transfer are provided to cultivate self-operating capabilities and support change management activities.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'DID-based Identity authentication and Establishment of a non-face-to-face civil service Platform',
    contents: (
      <div>
        This project aims to establish a platform for identity verification and non-face-to-face civil services based on Decentralized Identity (DID). 
        The primary objective is to eliminate blind spots in the use of mobile employee IDs and expand non-face-to-face services to improve efficiency. 
        Utilizing DID and blockchain technology, the platform securely and transparently manages user identity verification and electronic document processing. 
        This enhances the security and reliability of identity verification while improving accessibility to public services. Additionally, 
        the project aims to foster economic and industrial benefits by promoting non-face-to-face services and creating a technological foundation for new industries.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Building a Pension Entitlement Verification System for Public Officials Using Blockchain and Biometric Technology',
    contents: (
      <div>
        This project aims to establish a civil servant pension entitlement verification system using blockchain and biometric authentication technologies. 
        The goal is to digitize identity verification for pension recipients, maximize the efficiency of non-face-to-face services, 
        and enhance the transparency and reliability of administrative procedures. By utilizing biometric authentication, 
        the project simplifies the identity verification process for pension recipients and introduces a robust system to prevent fraudulent claims. 
        Additionally, the project employs Decentralized Identity (DID) technology to securely manage sensitive biometric information and grant users data sovereignty. 
        This system improves administrative efficiency, reduces the workload of pension agencies, and provides user-friendly services, especially for elderly pension recipients.
      </div>
    ),
  },
];

const Projects = () => {
  return (
    <Box as="section" id="projects" sx={styles.section}>
      <Container sx={styles.accordion.container}>
        <Box sx={styles.accordion}>
        <SectionHeader
          title="Projects"
          slogan="Blockchain Projects performed by FUNBLE & SGA Solutions"
        />
        <Accordion items={accordionData} />
        </Box>
      </Container>
    </Box>
  );
};
export default Projects;

const styles = {
  section: {
    overflow: 'hidden',
    pt: ['115px', null, null, '140px', '150px', '170px', '185px'],
    pb: ['60px', '75px', null, '90px', '110px', '120px', '140px', '160px'],
  },
  accordion: {
    container: {
      maxWidth: '900px',
    },
  },
};

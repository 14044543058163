const MENU_DATA = [
  {
    path: 'certificates',
    label: 'Certificates & Awards',
  },
  {
    path: 'patents',
    label: 'Patents',
  },
  {
    path: 'projects',
    label: 'Project',
  },
];
export default MENU_DATA;

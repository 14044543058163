/** @jsx jsx */
import { jsx, Box, Container, Image, Heading } from 'theme-ui';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import SectionHeader from 'components/section-header';

import { certificates, awards } from 'assets/assets';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const CERTIFICATES_DATA = [
  {
    image: awards.BabyUnicorn,
    title: 'Baby Unicorn Company',
  },
  {
    image: awards.TechProtectionLeading,
    title: 'Tech Protection Leading Company',
  },
  {
    image: awards.DeepTech,
    title: 'Deep Tech Enterprise Awards',
  },
  {
    image: awards.InfoSecurity,
    title: 'Information Security Awards',
  },
  {
    image: awards.StartupNEST,
    title: 'Start-up NEST Company',
  },
  {
    image: awards.GlobalCollabProgram,
    title: 'Global Corporate Collaboration Program',
  },
  {
    image: awards.StartupLeapPackage,
    title: 'Startup Growth Package Support Program',
  },
  {
    image: certificates.Iso27001,
    title: 'ISO 27001',
  },
  {
    image: certificates.Iso27701,
    title: 'ISO 27701',
  },
  {
    image: certificates.SandboxLicense,
    title: 'Korea FSC Reguratory Sandbox',
  },
  {
    image: certificates.VentureEnterprise,
    title: 'Venture Enterprise',
  },
  {
    image: certificates.InnoBiz,
    title: 'Innovation Business',
  },
]

const Certificates = () => {
  return (
    <Box as="section" id="certificates" sx={styles.section}>
      <Container>
        <SectionHeader title="Certificates & Awards" slogan="" />
        <Carousel
          responsive={responsive}
          arrows={false}
          autoPlay={true}
          autoPlaySpeed={2000}
          infinite={true}
          transitionDuration={1000} 
          containerClass="carousel-container"
          itemClass="carousel-item" 
        >
          {CERTIFICATES_DATA.map(({ image, title }, index) => (
            <Box key={`key-${index}`} sx={styles.certificate_post}>
              <Box sx={styles.imageWrap}>
                <Image src={image} alt="certificate image" />
              </Box>
              <Heading as="h3">{title}</Heading>
            </Box>
          ))}
        </Carousel>
      </Container>
    </Box>
  )
};

export default Certificates;

const styles = {
  section: {
    overflow: 'hidden',
    pt: ['115px', null, null, '140px', '150px', '170px', '185px'],
  },
  item: {
    border: '1px solid black',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    width: '200px', 
    height: '250px', 
    marginRight: '20px', 
    boxSizing: 'border-box',
  },
  '.carousel-item': {
    paddingRight: '20px !important', 
  },
  certificate_post: {
    mb: ['32px', null, null, null, 0],
    flex: ['0 0 50%', null, '0 0 50%', null, '0 0 25%'],
    textAlign: 'center',
    h3: {
      fontSize: ['18px', null, null, null, null, '20px'],
      lineHeight: 1.45,
      letterSpacing: '-0.5px',
      fontWeight: '500',
      color: '#02073E',
      mt: ['18px', '20px', null, null, '25px', '30px', null, '40px'],
      mb: ['10px', '15px', null, null, null, '20px'],
      px: '10px',
    },
    p: {
      maxWidth: '266px',
      mx: 'auto',
      color: '#02073E',
      fontSize: ['14px', '15px'],
      lineHeight: 2,
      px: [null, null, null, null, '5px', 0],
    },
  },
  imageWrap: {
    display: 'flex',
    minHeight: ['auto', '83px'],
    alignItems: 'center',
    justifyContent: 'center',
    img: {
      height: ['200px', null, null, null, '250px'],
    },
  },
};